import { Link } from "gatsby"
import React from "react"

export default function MapMenu(props) {
  const openMenu = () => {
    const menu = document.getElementsByClassName("side-menu-wrapper")[0]
    if (!menu) return

    if (menu.classList.contains("slide-in")) {
      menu.classList.remove("slide-in")
      menu.classList.add("slide-out")
    } else {
      menu.classList.remove("slide-out")
      menu.classList.add("slide-in")
    }

    const arrow = document.getElementsByClassName("menu-open-button")[0]
    if (!arrow) return

    if (arrow.classList.contains("rotate-360")) {
      arrow.classList.remove("rotate-360")
      arrow.classList.add("rotate-180")
    } else {
      arrow.classList.remove("rotate-180")
      arrow.classList.add("rotate-360")
    }
  }

  return (
    <div className="side-menu-wrapper">
      <div className="map-menu-wrapper">
        <div className="map-menu">
          <h2>Maps</h2>
          <hr />
          <ul>
            {props.availableMaps.map(node => {
              const nodeName = node.parent.name
                .split("_")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
              return (
                <li key={node.parent.id}>
                  <button
                    title={`View map of ${nodeName}`}
                    onClick={() => {
                      props.changeMap(node)
                      openMenu()
                      console.log(node)
                    }}
                  >
                    {nodeName}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="side-menu-edge">
        <button
          className="menu-open-button"
          title="Toggle menu"
          onClick={openMenu}
        >
          &gt;
        </button>

        <div className="home-link">
          <Link to="/">&lt; Home</Link>
        </div>
      </div>
    </div>
  )
}
