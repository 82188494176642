import React, { useEffect } from "react"
import MapTools from "./MapTools"

export default function MapController(props) {
  useEffect(() => {
    // Prevent scrolling, since scroll is used for zoom.
    document.body.style.overflow = "hidden"

    return function cleanup() {
      // Resets overflow to normal after Map unmounts.
      document.body.style.overflow = "auto"
    }
  })

  let scale = 1
  const scaleRange = { min: 1, max: 4 }
  const zoomSpeed = 1.05
  let panning = false
  let cursor = { x: 0, y: 0 }
  let start = { x: 0, y: 0 }

  const setScale = newScale => {
    // Constrain scale.
    scale = Math.min(Math.max(newScale, scaleRange.min), scaleRange.max)
  }

  const setTransform = target => {
    // Constrain the translation to keep the image in frame.
    const map = { width: target.offsetWidth, height: target.offsetHeight }
    cursor.x = Math.min(Math.max(cursor.x, -map.width * (scale - 1)), 0)
    cursor.y = Math.min(Math.max(cursor.y, -map.height * (scale - 1)), 0)

    target.style.transform = `translate(${cursor.x}px, ${cursor.y}px) scale(${scale})`
  }

  const zoomClick = zoomIn => {
    const target = document.querySelector(".map-area")

    // Getting the previous center of the image.
    const prevCenter = {
      x: (-target.offsetWidth * (scale - 1)) / 2,
      y: (-target.offsetHeight * (scale - 1)) / 2,
    }

    // Get position as shift from center.
    let xs = (prevCenter.x - cursor.x) / scale
    let ys = (prevCenter.y - cursor.y) / scale

    if (zoomIn) {
      setScale(scale * (zoomSpeed * 1.3))
    } else {
      setScale(scale / (zoomSpeed * 1.3))
    }

    // Image shift
    cursor = {
      x: (-target.offsetWidth * (scale - 1)) / 2 - xs * scale,
      y: (-target.offsetHeight * (scale - 1)) / 2 - ys * scale,
    }

    target.style.transition = "0.5s"
    setTransform(target)
  }

  const zoom = e => {
    e.preventDefault()
    const target = e.currentTarget

    // Cursor position, not relative to shifting.
    let xs = (e.clientX - cursor.x) / scale
    let ys = (e.clientY - cursor.y) / scale

    let delta = e.wheelDelta ? e.wheelDelta : -e.deltaY

    if (delta > 0) {
      setScale(scale * zoomSpeed)
    } else {
      setScale(scale / zoomSpeed)
    }

    // Scaled cursor shift
    cursor = { x: e.clientX - xs * scale, y: e.clientY - ys * scale }

    target.style.transition = "0.35s"
    setTransform(target)
  }

  const grabElement = e => {
    e.preventDefault()
    const target = e.currentTarget

    // Record initial cursor position and start movement.
    start = { x: e.clientX - cursor.x, y: e.clientY - cursor.y }
    panning = true

    target.style.transition = "0s"
  }

  const dragElement = e => {
    e.preventDefault()

    const target = e.currentTarget

    if (!panning) {
      return
    }

    // Apply image shift.
    cursor = { x: e.clientX - start.x, y: e.clientY - start.y }

    setTransform(target)

    target.style.cursor = "all-scroll"

    // Handles if the mouse goes ouside the window
    if (target.releasePointerCapture) {
      target.releasePointerCapture(e.pointerId)
    }

    if (target.setPointerCapture) {
      target.setPointerCapture(e.pointerId)
    }
  }

  const releaseElement = e => {
    e.preventDefault()

    const target = e.currentTarget
    panning = false // Stop movement.
    target.style.cursor = "default"

    // Handles if the mouse goes ouside the window
    if (target.releasePointerCapture) {
      target.releasePointerCapture(e.pointerId)
    }

    if (target.setPointerCapture) {
      target.setPointerCapture(e.pointerId)
    }
  }

  return (
    <>
      <div
        className="map-area"
        role="presentation"
        onWheel={zoom}
        onMouseDown={grabElement}
        onMouseMove={dragElement}
        onMouseUp={releaseElement}
        onDoubleClick={() => zoomClick(true)}
      >
        {props.children}
      </div>
      <MapTools zoom={zoomIn => zoomClick(zoomIn)} />
    </>
  )
}
