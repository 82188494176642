import React from "react"

const MapTools = props => {
  return (
    <div
      className="map-tools-wrapper"
      role="presentation"
      onMouseEnter={e => (e.currentTarget.style.transition = "0.3s")}
      onMouseLeave={e => (e.currentTarget.style.transition = "2s")}
    >
      <button className="zoom-button" onClick={() => props.zoom(true)}>
        +
      </button>
      <button className="zoom-button" onClick={() => props.zoom(false)}>
        -
      </button>
    </div>
  )
}

export default MapTools
