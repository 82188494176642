import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import MapMenu from "../components/map/MapMenu"
import MapController from "../components/map/MapController"
import Seo from "../components/Seo"

const OldMap = () => {
  // Query for maps in folder src/images/map
  const maps = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            gatsbyImageData(
              breakpoints: 10
              placeholder: DOMINANT_COLOR
              quality: 100
            )
            parent {
              ... on File {
                name
                dir
                id
              }
            }
          }
        }
      }
    }
  `)

  const allMaps = maps.allImageSharp.edges
    .map(edge => {
      return edge.node
    })
    .sort((a, b) => a.parent.name.localeCompare(b.parent.name))

  // Used for iterating through different maps.
  const [img, setImg] = useState(allMaps[0])

  return (
    <>
      <Seo title="Map" />
      <div className="map-page">
        {img && (
          <>
            <MapMenu
              availableMaps={allMaps}
              changeMap={image => setImg(image)}
            />
            <MapController>
              <GatsbyImage
                image={getImage(img)}
                alt={"Map of Eastern Lumberia"}
                imgClassName={"map-image"}
                loading={"eager"}
                objectFit={"contain"}
              />
            </MapController>
          </>
        )}
      </div>
    </>
  )
}

export default OldMap
